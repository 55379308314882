.page_title_custom h4 {
	font-style: normal;
	font-weight: normal;
	font-size: 1.71rem;
	line-height: 28px;
}

@media screen and (max-width: 375px){
	.page_title_custom{
		flex-wrap: wrap;
	}

	.page_title_custom > div{
		margin-top: 1rem;
	}
}

@media screen and (max-width: 767px) {
	.page_title_custom{
		margin: 0 1.5rem;
	}
}