.container {
	background-position: center;
	background-attachment: fixed;
}

.sectionsContainer {
	max-width: 1280px;
	margin: 0 auto;
}

.landing_sect {
	position: relative;
}

.landing_sect > img {
	width: 100%;
}
.landing_sect > svg:first-of-type {
	position: absolute;
	top: 0;
	left: 60px;
	animation: bounce 2s ease-in-out infinite;
}

.landing_sect > svg:nth-of-type(2) {
	position: absolute;
	top: 0;
	right: 0;
	animation: bounce 2s ease-in-out infinite;
}
.landing_sect > svg:nth-of-type(3) {
	position: absolute;
	bottom: 70px;
	right: -15px;
	animation: bounce 2s ease-in-out infinite;
}
.firstCover {
	padding: 86px 20px;
	background: url("../../../assets/images/lines.png");
	background-color: var(--bg-green);
	background-size: contain;
	background-position: center;
}

/* .sectionsContainer section:first-of-type {
	flex-direction: column;
} */

.notice {
	display: inline-block;
	background-color: var(--transparent-white);
	color: var(--white-color);
	padding: 5px 15px 5px 12px;
	border-radius: 100px;
	animation: tilt-shaking 0.35s infinite;
	animation-iteration-count: 7;
	font-size: 0.75rem;
}

.notice span {
	display: inline-block;
	border-radius: 100px;
	background-color: var(--light-red);
	padding: 5px 10px;
	margin-right: 10px;
	font-size: 0.75rem;
}

.cardsContainer {
	width: 90%;
	margin: -50px auto;
	margin-bottom: 50px;
	padding: 20px;
	background-color: var(--disabled);
	border-radius: 12px;
	z-index: 999;
}
.btn {
	border-radius: 4px;
	background: var(--dark-bg-text-color);
	padding: 10px 16px;
	font-size: 18px;
	font-style: normal;
	font-weight: 450;
	line-height: 15.96px;
	color: var(--primary);
	border: none;
	margin-top: 25px;
}
.card {
	display: inline-block;
	background: var(--white-color);
	border-radius: 6px;
	padding: 40px;
	width: 100%;
}

.card > * {
	margin-bottom: 15px;
}

.card p {
	color: var(--text-neutral);
	text-align: left !important;
}

.cardLinks {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 30px;
}

.cardLinks a {
	text-decoration: none;
	color: var(--an-green);
	font-weight: 500;
}

.cardLinks a:hover {
	text-decoration: underline;
	color: var(--an-green);
}

.welcome_circle {
	position: absolute;
	bottom: 0;
	animation-name: spin;
	animation-duration: 10000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	transform-origin: center;
}

.sectionsContainer section h1 {
	font-size: 2rem;
	line-height: 40.6px;
	color: var(--white-color);
}

.sectionsContainer section p {
	font-size: 1.14rem;
	line-height: 25.888px;
	margin-top: 16px;
	color: var(--neutral-light);
	text-align: left !important;
}

.application_section_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 50px 0px;
}

.application_section_header h2,
.application_section_header p {
	text-align: center;
}

.home_footer {
	box-shadow: 0px 2px 6px 0px var(--box_shadow);
	padding: 16px;
	bottom: 0;
	position: relative;
	width: 100%;
}

.call_centre_number {
	font-weight: 500;
	color: var(--alt-text-color-tint);
}

.call_centre_number > span:first-of-type {
	color: var(--light-text-color);
}

.call_centre_number > span:last-of-type {
	color: var(--text-green);
}

.fab {
	background-color: var(--primary);
	width: 2.7rem;
	height: 2.7rem;
	border-radius: 4px;
	position: absolute;
	right: 20px;
	top: -50px;
	transition: all 0.3s ease;
}
.fab:hover {
	bottom: 5%;
	cursor: pointer;
}

.footer_outer_container {
	margin-top: 100px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes tilt-shaking {
	0% {
		transform: translate(0, 0) rotate(0deg);
	}
	25% {
		transform: translate(5px, 5px) rotate(5deg);
	}
	50% {
		transform: translate(0, 0) rotate(0eg);
	}
	75% {
		transform: translate(-5px, 5px) rotate(-5deg);
	}
	100% {
		transform: translate(0, 0) rotate(0deg);
	}
}

.sectionsContainer section {
	flex-direction: column;
	justify-content: center;
	row-gap: 50px;
}

.sectionsContainer section svg {
	display: none;
}

@media (min-width: 768px) {
	.notice,
	.notice span {
		font-size: 1rem;
	}

	.sectionsContainer section {
		flex-direction: row;
		justify-content: initial;
	}

	.sectionsContainer section svg {
		display: block;
	}

	.sectionsContainer section:nth-of-type(1) > * {
		margin-bottom: 20px;
	}

	.cardsContainer {
		padding: 40px;
		margin: -50px auto;
		margin-bottom: 50px;
		width: 90%;
	}

	.card {
		padding: 50px;
	}

	.sectionsContainer section h1 {
		font-size: 2rem;
		line-height: 40.6px;
		color: var(--white-color);
	}

	.sectionsContainer section p {
		font-size: 1.14rem;
		line-height: 25.888px;
		margin-top: 16px;
		color: var(--neutral-light);
	}
}

@media (min-width: 1200px) {
	.sectionsContainer {
		padding: 50px;
		padding-top: 50px;
	}

	.sectionsContainer section h1 {
		font-size: 4.7rem;
		max-width: 709px;
		line-height: 81.92px;
		color: var(--white-color);
	}

	.sectionsContainer section p {
		font-size: 1.43rem;
		line-height: 32px;
		margin-top: 16px;
		color: var(--neutral-light);
		max-width: 571px;
	}

	.landing_sect {
		width: 100%;
		max-width: 462px;
		background-position: center;
		background-attachment: fixed;
		background-size: cover;
		position: relative;
	}

	.welcome_circle {
		position: absolute;
		bottom: 0;
		animation-name: spin;
		animation-duration: 10000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		transform-origin: center;
	}

	.cardsContainer {
		padding: 80px;
		margin: -100px auto;
		margin-bottom: 100px;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes bounce {
	0% {
		transform: translateY(-30px);
	}

	50% {
		transform: translateY(0px);
	}

	100% {
		transform: translateY(-30px);
	}
}
