.btn_container {
	width: 191px;
	border-radius: 2px;
	background: var(--white-color);
	margin-top: 10px;
	border: 1px solid var(--neutral-n-30);
}

.btn_container button {
	padding: 10px 16px;
	border: none;
	width: 100%;
	background: var(--white-color);
	text-align: left;
}

.btn_container button:disabled {
	color: #a5adba;
	background: var(--disabled);
	cursor: not-allowed;
}
