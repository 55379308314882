.notification_banner {
	align-items: center;
	background: var(--lighter-green);
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 1000;
	width: 100%;
	padding: 1rem 0rem;
}

.bold_content {
	color: var(--alt-text-color-tint);
	font-weight: 900;
	padding-left: 1.5rem;
	padding-right: 0.5rem;
}

.checking {
	display: flex;
	align-items: center;
}

.content {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 14px;
	color: var(--alt-text-color-tint);
	gap: 0.5rem;
}

.close_btn {
	bottom: 24px;
	height: 24px;
	background: none;
	border: none;
}
.button {
	padding-left: 0rem 5rem;
}

/* Media query for screens smaller than 600px (mobile) */
@media (max-width: 600px) {
	.notification_banner {
		width: 100%;
		max-width: 100%;
		box-sizing: border-box;
		padding: 0 10px;
		font-size: 12px;
	}

	.close_btn {
		padding-right: 5px;
	}
	.content {
		display: block;
		padding: 1rem 1rem;
	}
	.button {
		padding-left: 0rem;
	}
}
