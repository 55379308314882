.module-children {
	height: calc(100% - 50px);
	overflow-y: auto;
	scroll-behavior: smooth;
	
}

.res-module {
	height: 100%;
	width: 100%;
	position: relative;
	background: transparent;
}

.res-module-header {
	position: sticky;
	width: 100%;
	top: 0;
}

.list-group-item {
	padding-left: 24px;
}

.res-flag-error-message {
	font-style: normal;
	font-weight: 450;
	font-size: 1rem;
	line-height: 20px;
	color: #42526e;
	margin: 6px 0;
}

.res-block-icon {
	width: 24px;
	height: 24px;
	border-radius: 3px;
	display: inline-block;
}

@media screen and (max-width: 760px) {
	.module-children{
		padding: 0 1.5rem;
	}
}